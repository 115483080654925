<template>
  <div :class="['top-bar grid', {scrolled: scrollYOffset > 0}]">
    <div class="logo-area">
      <router-link custom :to="{name: 'Home'}" v-slot="{ navigate, href }">
        <a :href="href" class="logo-container" @click="navigate">
          <div><icon-set icon="logo-earlyaccess" height="48px"></icon-set></div>
        </a>
      </router-link>
    </div>
    <div class="static flex center">
      <div>
        <div class="search flex" v-show="!hideSearch">
          <search-bar style="flex-grow: 1" ref="search" @searched="searched" variant="navbar" size="sm"/>
          <slot name="afterSearch"></slot>
        </div>
        <div class="links" v-show="false/*!hideLinks*/">
          <div class="link">Popular Games</div>
          <div class="link">New Releases</div>
          <div class="link">Upcoming</div>
          <div class="link"><b-dropdown variant="link"><template slot="button-content">More</template></b-dropdown></div>
        </div>
        <div class="links small" v-show="false/*!hideLinks*/">
          <div class="link">Popular</div>
          <div class="link">New</div>
          <div class="link">Upcoming</div>
          <div class="link"><b-dropdown variant="link"><template slot="button-content">More</template></b-dropdown></div>
        </div>
      </div>
    </div>
    <div class="user-container flex">
      <div id="currency" class="v-h-center">
        <b-form-select
          v-model="selectedCurrency"
          :options="currencyOptions"
          @change="currencySelected"
          style="font-size: 0.7rem;"
        ></b-form-select>
      </div>
      <!-- Layout right to left to support user stuff always on the right -->
      <user-dropdown></user-dropdown>
    </div>
  </div>
</template>

<script>
import { BDropdown, BFormSelect } from 'bootstrap-vue';
import IconSet from '@/components/IconSet';
import UserDropdown from '@/components/UserDropdown.vue';
import SearchBar from '@/components/SearchBar';
import _ from 'underscore';

export default {
  name: 'NavTopBar',
  components: {
    BDropdown,
    BFormSelect,
    IconSet,
    UserDropdown,
    SearchBar
  },
  props: {
    searchTo: {
      type: String
    },
    hideLinks: {
      type: Boolean,
      default: false
    },
    hideSearch: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      scrollYOffset: 0,
      selectedCurrency: undefined
    }
  },
  computed: {
    title () {
      return this.$route ? this.$route.meta.title : '';
    },
    currencyOptions () {
      return this.metadata ? _.keys(this.metadata.exchange_rates) : [];
    },
    currencyState () {
      return this.$store.state.currency;
    }
  },
  methods: {
    searched (search) {
      // @TODO: Brett review (then clean up logs)
      // @TODO: PageBase has the search component which is hardcoded to "Browse"
      // Need to be able to pass that in
      if (this.$route.query && this.$route.query.q === search) return;
      let query = { q: search };
      // if on the same route we're going to, just replace/add query to existing query
      let bothBrowse = this.$route.name === this.searchTo || this.$route.name === "Library"
      var newQueryObj = bothBrowse ? _.extend(JSON.parse(JSON.stringify(this.$route.query)), query) : query;

      console.log("newQueryObj")
      console.table(newQueryObj)
      console.log("SearchTo:" + this.searchTo)
      console.log("Route Name:" + this.$route.name)
      console.log("Equal:" + bothBrowse)
      console.table(query)

      let routeName = this.$route.name === "Home" || this.$route.name === "Details" ? this.searchTo : this.$route.name
      this.$router.push({ name: routeName, query: newQueryObj });
    },
    handleScroll () {
      this.scrollYOffset = window.pageYOffset;
    },
    currencySelected (val) {
      this.$store.dispatch('setCurrency', val);
    }
  },
  beforeMount() {
    window.addEventListener('scroll', this.handleScroll);
  },
  created () {
    this.selectedCurrency = this.$store.state.currency;
  },
  watch: {
    currencyState (newVal) {
      this.selectedCurrency = newVal;
    }
  }
}
</script>

<style scoped lang="scss">
@import "@/assets/styles/_variables.scss";

.top-bar {
  height: $headerHeight;
  width: 100%;
  position: fixed;
  top: 0;
  left: 0;
  grid-template-columns: 1fr 4fr 1fr;
  align-items: center;
  z-index: 251;
  padding: 16px 24px;
  transition: all .2s ease-in-out;
  background-color: #3E4047;
  // background-image: linear-gradient(var(--nav-gradient-top), var(--nav-gradient-bottom));
  &.scrolled {
    box-shadow: 0 2px 3px rgba(0,0,0,0.3);
  }
  .logo-area {
    width: 225px;
  }
  .logo-container {
    text-align: center;
    cursor: pointer;
    > .logo {
      margin-bottom: 10px;
    }
  }
  > .static {
    flex-grow: 1;
    > div {
      width: 70%;
      max-width: 600px;
      min-width: 240px;
      .search {
        margin-bottom: 0.7em;
      }
    }
  }
}
.user-container {
  align-items: center;
  justify-content: end;
  #currency {
    margin: 0 16px;
    min-width: 64px;
  }
}

.links {
  display: flex;
  justify-content: space-evenly;
  column-gap: 16px;
  .link {
    color: #A9A9A9;
    font-size: 1.2em;
  }
}
.links.small {
  display: none;
}

.top-bar .search {
  column-gap: 8px;
}
@media (max-width: $mediumWidth) {
  .top-bar {
    padding-left: 0;
    padding-right: 16px;
  }
  .top-bar > .user-container ::v-deep .dropdown-toggle .user-badge {
    min-width: 0;
    .user-info-cell > div:not(.b-avatar) {
      display: none;
    }
  }
  .top-bar .search {
    display: flex;
    align-items: center;
  }
  .top-bar .links.small {
    display: flex;
  }
  .top-bar .links:not(.small) {
    display: none;
  }
}

@media (max-width: $smallWidth) {
  .top-bar {
    height: $smallHeaderHeight;
    padding: 8px 0;
    row-gap: 8px;
    .static {
      grid-row-start: 2;
      grid-column-end: span 3;
    }
    .user-container {
      position: absolute;
      right: 16px;
      top: 16px;
    }
    .logo-area {
      grid-column-end: span 2;
      width: auto;
      justify-content: center;
    }
  }
}

@media (max-width: $mobileWidth) {
  .top-bar {
    height: $mobileHeaderHeight;
    > .user-container {
      position: static;
      justify-content: space-around;
      grid-column-end: span 3;
      #currency {
        margin: 0;
      }
    }
    > .static {
      grid-row-start: 3;
      > div {
        width: 80%;
      }
    }
    .logo-area {
      grid-column-end: span 3;
    }
  }
}
</style>
