<template>
  <div v-if="!loading" style="height: 100%">
    <auth-wrapper :required-level="$options.AUTH_LEVELS.MOD" :allowed-override="isPendingAndCreator" class="sticky-below-nav" style="margin: 8px; z-index: 200">
      <div class="flex" style="gap: 12px">
        <b-button variant="danger" v-show="editMode" v-b-modal.delete-confirm-modal>Delete Game</b-button>
        <b-button :variant="editMode ? 'success' : 'outline-secondary'" :pressed.sync="editMode" style="box-shadow: none">Edit Mode: {{editMode ? 'On' : 'Off'}}</b-button>
      </div>
    </auth-wrapper>
    <div class="game-details">
      <div class="w-100">
        <div class="header">
          <div :class="['header-bg', headerBgClass]">
            <g-image v-if="images" fluid hide-blur-hash right
              class="bg-img"
              :image-object="headerBgImage"
              :image-size-idx="4"></g-image>
            <div class="l-overlay"></div>
            <div class="overlay" v-if="images"></div>
          </div>
          <div class="header-content content flex mobile-switch" style="gap: 16px; align-items: begin; position: relative">
            <div class="img-container flex center">
              <g-image v-if="images"
                :image-object="(getImagesByTypeFromRecord(game.images, 'Front Box Art') || {})[0]"
                :draw-width="240"
                :draw-height="240"
                :image-size-idx="3"
                hide-blur-hash-when-loaded></g-image>
            </div>
            <div class="flex-col">
                <div class="system flex">
                  <div v-if="game.country_id && metadata" class="flag">
                    <flag-icon style="margin-right: 16px; font-size: 0.9rem" :country="metadata.countries[game.country_id]"></flag-icon>
                  </div>
                  <editable-field :editable="editMode" required field-name="platform_id" :field-value="parseListForMatchingId(platformsList, game.platform_id)" :save-func="editFieldFunc" type="select" :select-config="{ options: platformsList }">
                    <template v-slot:option="opt">
                      <platform :platform-id="opt.id"></platform>
                    </template>
                    <template v-slot:singleLabel="opt">
                      <platform :platform-id="opt.id" field="short"></platform>
                    </template>
                    <platform :platform-id="game.platform_id"></platform>
                  </editable-field>
                </div>
                <div style="margin-bottom: 12px">
                  <div class="title">
                    <editable-field :editable="editMode" required field-name="title" :field-value="game.title" :save-func="editFieldFunc" type="text">
                      <div>{{game.title}}</div>
                    </editable-field>
                  </div>
                  <div style="color: var(--lightgrey-font-color);" class="italic">
                    <editable-field :editable="editMode" field-name="alt_titles" :field-value="game.alt_titles" :save-func="editFieldFunc" type="multi-text">
                      {{formattedAltTitles}}
                    </editable-field>
                  </div>
                </div>
                <div class="kpis">
                  <component :is="getLink('metacritic') ? 'a' : 'div'" class="kpi" :href="getLink('metacritic')" target="_blank">
                    <div style="font-size: 0.8rem" class="label">Metascore</div>
                    <meta-score :score="metascore"></meta-score>
                  </component>
                </div>
            </div>
          </div>
        </div>
      </div>
      <div class="tabs">
        <div class="tabs-content">
          <b-form-radio-group
            id="tabs"
            v-model="selectedTab"
            button-variant="outline-primary"
            size="lg"
            name="radio-btn-outline"
            buttons
            @change="selectedTabChanged"
          >
            <b-form-radio v-for="tab in visibleTabs" :value="tab.value" :key="tab.value">
              <div class="flex center">
                <b-icon v-if="tab.icon" :icon="tab.icon" style="margin-right: 0.3em" width="0.8em" height="0.8em"></b-icon>
                <span>{{tab.text}}</span>
              </div>
            </b-form-radio>
          </b-form-radio-group>
        </div>
      </div>
      <div class="content" style="position: relative; z-index: 10; background-color: var(--background-color)">
        <div class="body" v-show="selectedTab === 1">
          <div style="margin-top: 16px">
            <image-gallery v-if="game.images"
              :new-image-func="uploadNewImage"
              :edit-image-func="editImageMetadata"
              :replace-image-func="replaceImage"
              :delete-image-func="removeImage"
              :images="game.images"
              :images-of="game.title"
              :edit-mode="editMode">
            </image-gallery>
          </div>
          <div class="flex">
            <div class="left-col">
              <div class="main">
                <div class="main-attributes section card">
                  <div class="section-header title">Info</div>
                  <div class="content">
                    <auth-wrapper class="label-value" :required-level="$options.AUTH_LEVELS.ADMIN">
                      <div class="label">[ITEM STATE]</div>
                      <div class="value">
                        <editable-field :editable="editMode" field-name="item_state" :field-value="parseListForMatchingId(metadata.item_states, game.item_state)" :save-func="editItemStateFunc" type="select" :select-config="{ options: metadata.item_states, label: 'name', value: 'id' }" :required-user-level="$options.AUTH_LEVELS.ADMIN" required>
                          {{parseListForMatchingId(metadata.item_states, game.item_state).name}}
                        </editable-field>
                      </div>
                    </auth-wrapper>
                    <div class="label-value">
                      <div class="label">Category</div>
                      <div class="value">
                        <editable-field :editable="editMode" field-name="category_id" :field-value="parseListForMatchingVal(assignableCategories, {value: game.category_id})" :save-func="editFieldFunc" type="select" :select-config="{ options: assignableCategories, label: 'label', value: 'value' }" :required-user-level="$options.AUTH_LEVELS.MOD" required>
                          {{parseListForMatchingVal(assignableCategories, {value: game.category_id}).label}}
                        </editable-field>
                      </div>
                    </div>
                    <div class="label-value">
                      <div class="label">Release Date</div>
                      <div class="value">
                        <editable-field :editable="editMode" required field-name="release_date" :field-value="game.release_date ? game.release_date * 1000 : game.release_date" :save-func="editFieldFunc" type="date">
                          {{game.release_date | date-seconds}}
                        </editable-field>
                      </div>
                    </div>
                    <div class="label-value">
                      <div class="label">Release Type</div>
                      <div class="value">
                        <editable-field :editable="editMode" required field-name="release_type" :field-value="parseListForMatchingId(metadata.release_types, game.release_type)" :save-func="editFieldFunc" type="select" :select-config="{ options: metadata.release_types, label: 'name', value: 'id' }">
                          {{parseListForMatchingId(metadata.release_types, game.release_type).name}}
                        </editable-field>
                      </div>
                    </div>
                    <div class="label-value">
                      <div class="label">Publisher(s)</div>
                      <div class="value">
                        <editable-field :editable="editMode" field-name="pubs" :field-value="parseListForMatchingIds(values(publishers), game.pubs)" :save-func="editFieldFunc" type="multiselect" :select-config="{ options: values(publishers), label: 'name', value: 'id' }">
                          <pill :text="pub.name" :title="pub.name" v-for="pub in parseObjectKeysForMatchingIds(publishers, game.pubs)" :key="pub.id" :variant="searchGroupMeta.Publisher.variant"></pill>
                        </editable-field>
                      </div>
                    </div>
                    <div class="label-value">
                      <div class="label">Developer(s)</div>
                      <div class="value">
                        <editable-field :editable="editMode" field-name="devs" :field-value="parseListForMatchingIds(values(developers), game.devs)" :save-func="editFieldFunc" type="multiselect" :select-config="{ options: values(developers), label: 'name', value: 'id' }">
                          <pill :text="dev.name" :title="dev.name" v-for="dev in parseObjectKeysForMatchingIds(developers, game.devs)" :key="dev.id" :variant="searchGroupMeta.Developer.variant"></pill>
                        </editable-field>
                      </div>
                    </div>
                    <div class="label-value">
                      <div class="label">Region</div>
                      <div class="value">
                        <editable-field :editable="editMode" required field-name="country_id" :field-value="parseListForMatchingId(metadata.countries, game.country_id)" :save-func="editFieldFunc" type="select" :select-config="{ options: values(metadata.countries), label: 'name', value: 'id' }">
                          <div v-if="game.country_id && metadata" class="flag flex">
                            <flag-icon style="margin-right: 0.5em; font-size: 15px" :country="metadata.countries[game.country_id]"></flag-icon>
                            <span>{{parseListForMatchingId(metadata.countries, game.country_id).abbr}}</span>
                          </div>
                          <template v-slot:option="option">
                            <div class="flex">
                              <flag-icon style="margin-right: 0.5em; font-size: 15px" :country="metadata.countries[option.id]"></flag-icon>
                              <span>{{option.abbr}}</span>
                            </div>
                          </template>
                          <template v-slot:singleLabel="option">
                            <div class="flex">
                              <flag-icon style="margin-right: 0.5em; font-size: 15px" :country="metadata.countries[option.id]"></flag-icon>
                              <span>{{option.abbr}}</span>
                            </div>
                          </template>
                        </editable-field>
                      </div>
                    </div>
                    <div class="label-value">
                      <div class="label">Genres</div>
                      <div class="value">
                        <editable-field :editable="editMode" field-name="genres" :field-value="parseListForMatchingIds(values(genres), game.genres)" :save-func="editFieldFunc" type="multiselect" :select-config="{ options: values(genres), label: 'name', value: 'id' }">
                          <pill :text="genre.name" :title="genre.name" v-for="genre in parseObjectKeysForMatchingIds(genres, game.genres)" :key="genre.id" :variant="searchGroupMeta.Genre.variant"></pill>
                        </editable-field>
                      </div>
                    </div>
                    <div class="label-value">
                      <div class="label">Rating</div>
                      <editable-field :editable="editMode" field-name="rating_id" :field-value="rating" :save-func="editFieldFunc" type="select" :select-config="{ options: metadata.parental_ratings, label: 'name', value: 'id' }">
                        <div v-if="rating && rating.name" style="margin-top: -3px">
                          <b-img v-if="rating.file" :src="getImageURLByType(rating.file, 0, 'RATINGS')" :title="rating.name" height="20"/>
                          <span style="margin-left: 0.5em">{{rating.name}}</span>
                        </div>
                        <template v-slot:option="option">
                          <div class="flex">
                            <b-img v-if="option.file" :src="getImageURLByType(option.file, 0, 'RATINGS')" :title="option.name" height="20"/>
                            <span style="margin-left: 0.5em">{{option.name}}</span>
                          </div>
                        </template>
                        <template v-slot:singleLabel="option">
                          <div class="flex">
                            <b-img :src="getImageURLByType(option.file, 0, 'RATINGS')" :title="option.name" height="20"/>
                            <span style="margin-left: 0.5em">{{option.name}}</span>
                          </div>
                        </template>
                      </editable-field>
                    </div>
                    <div class="label-value">
                      <div class="label">Players</div>
                      <div class="value">
                        <editable-field :editable="editMode" field-name="num_players" :field-value="gameSpecific.num_players" :save-func="editFieldFunc" type="number">
                          {{gameSpecific.num_players}}
                        </editable-field>
                      </div>
                    </div>
                    <div class="label-value">
                      <div class="label">CoOp</div>
                      <div class="value">
                        <editable-field :editable="editMode" field-name="co_op" :field-value="gameSpecific.co_op" :save-func="editFieldFunc" type="boolean">
                          {{gameSpecific.co_op ? 'Yes' : 'No'}}
                        </editable-field>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="section card">
                  <div class="section-header title">Links</div>
                  <div class="content">
                    <div v-for="link in allLinks" :key="link.name" class="label-value">
                      <editable-field :editable="link.editable && editMode" :field-name="`${link.name}_url`" :field-value="link.link || linksMap[link.name]" :save-func="editFieldFunc" :ref="link.name + '-efp'" clear-btn>
                        <a :href="link.link || getLink(link.name)" target="_blank">{{link.label}}</a>
                        <template slot="pre" v-if="getLinkGuess(link.name)">
                          <div class="flex" style="font-size: 0.9em; color: var(--white); margin-bottom: 8px">
                            <span class="italic">Our Guess:</span>
                            <a :href="getLinkGuess(link.name)" style="margin-left: 5px" target="_blank">link</a>
                            <b-button variant="outline-primary" style="margin-left: 10px; padding: 2px 8px; font-size: 0.7rem;" title="Copy Down" @click="copyLinkGuess(link.name)">Copy Down <b-icon-arrow-down style="height: .8rem; vertical-align: middle; margin-top: -0.1rem;"/></b-button>
                          </div>
                        </template>
                        <template slot="clear-btn">
                          <b-button variant="outline-danger" style="font-size: 0.7rem" title="Unlink"><unlink-icon height="14" width="14" fill="currentColor" style="margin-top: -4px"></unlink-icon></b-button>
                        </template>
                      </editable-field>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="right-col">
              <div class="main g-container details">
                <div class="flex" style="width: 100%">
                  <div class="content flex-col" style="width: 100%; position: relative; row-gap: 24px">
                    <div class="card">
                      <div class="section-header">Summary</div>
                      <div class="content">
                        <div :class="['description', {expanded: summaryExpanded}]">
                          <editable-field :editable="editMode" field-name="overview" :field-value="game.overview" :save-func="editFieldFunc" type="text-area">
                            <p ref="summary">{{game.overview || '(No Overview)'}}</p>
                          </editable-field>
                        </div>
                        <div class="expand-link" v-if="showSummaryExpand || summaryExpanded">
                          <b-button variant="link" size="sm" @click="summaryExpanded = !summaryExpanded">
                            {{summaryExpanded ? 'Less' : 'More'}}
                          </b-button>
                        </div>
                      </div>
                    </div>
                    <div class="card">
                      <div class="title" style="justify-content: space-between">
                        <div>Pricing</div>
                        <div class="flex v-center affiliates">
                          <div class="grey">Affiliate Links <span style="font-size: 1.2em">*</span></div>
                          <div class="affiliate">
                            <a :href="getLink('ebay')" target="_blank"><icon-set icon="ebay" height="20" /></a>
                          </div>
                          <div class="affiliate">
                            <a :href="getLink('amazon')" target="_blank"><icon-set icon="amazon" height="22" /></a>
                          </div>
                        </div>
                      </div>
                      <div class="content">
                        <div class="prices">
                          <div class="label-value">
                            <icon-set icon="loose" height="28px"></icon-set>
                            <div class="label">Loose</div>
                            <price-pill :price="prices.Loose"></price-pill>
                          </div>
                          <div class="label-value">
                            <icon-set icon="cib" height="28px"></icon-set>
                            <div class="label">CiB</div>
                            <price-pill :price="prices.CIB"></price-pill>
                          </div>
                          <div class="label-value">
                            <icon-set icon="new" height="28px"></icon-set>
                            <div class="label">New</div>
                            <price-pill :price="prices.New"></price-pill>
                          </div>
                          <div class="label-value">
                            <icon-set icon="manual" height="28px"></icon-set>
                            <div class="label">Manual</div>
                            <price-pill :price="prices.ManualPrice"></price-pill>
                          </div>
                          <div class="label-value">
                            <icon-set icon="box" height="28px"></icon-set>
                            <div class="label">Box</div>
                            <price-pill :price="prices.BoxPrice"></price-pill>
                          </div>
                        </div>
                      </div>
                      <div class="flex v-center" style="justify-content: space-between; margin-top: 4px; font-size: 0.7rem; gap: 16px">
                        <div>
                          <span class="grey">*&nbsp;&nbsp;{{$options.AFFILIATE_DISCLOSURE}}</span>
                        </div>
                        <div>
                          <span class="grey">Powered by </span>
                          <a  style="white-space: nowrap" href="https://www.pricecharting.com/" target="_blank">PriceCharting<icon-set icon="pricecharting" height="12" style="padding-left: 3px" /></a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div v-show="selectedTab === 5" style="width: 100%; padding: 32px 16px; overflow-x: auto">
          <table id="edit-history">
            <thead>
              <th>Action</th>
              <th>What was Updated</th>
              <th>Previous Value</th>
              <th>New Value</th>
              <th>User</th>
              <th>Update Date</th>
            </thead>
            <tbody>
              <tr v-for="change in editHistory" :key="change.edit_id">
                <td>{{change.action}}</td>
                <td>{{change.column}}</td>
                <td>{{parseColumn(change.column, change.prev_value)}}</td>
                <td>{{parseColumn(change.column, change.new_value)}}</td>
                <td>{{change.user_name}}</td>
                <td>{{change.date | date-local-hms}}</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
    <b-modal hide-header id="delete-confirm-modal" @ok="deleteItem">Are you sure you want to delete this game?</b-modal>
  </div>
</template>

<script>
import _ from 'lodash';
import Vue from 'vue';
import GImage from '@/components/GImage';
import utils from '@/mixins/utilsMixin';
import imageHelperMixin from '@/mixins/ImageHelperMixin';
import { BFormRadioGroup, BFormRadio, BIcon, BImg, BIconArrowDown } from 'bootstrap-vue';
import EditableField from '@/components/EditableField';
import MetaScore from '@/components/MetaScore';
import Platform from '@/components/Platform';
import Pill from '@/components/Pill';
import PricePill from '@/components/PricePill';
import ImageGallery from '@/components/ImageGallery';
import FlagIcon from '@/components/FlagIcon';
import UnlinkIcon from '@/components/UnlinkIcon';
import AuthWrapper from '@/components/AuthWrapper';
import IconSet from '@/components/IconSet';
import {ITEM_STATES} from "@/store/constants.js";

export default {
  name: "GameDetails",
  metaInfo () {
    return {
      title: this.game.title ? `${this.game.title} | GAMEYE` : undefined,
      meta: [
        {vmid: "canonical", property: "canonical", content: window.location.origin + this.$route.fullPath}
      ]
    }
  },
  components: {
    GImage,
    BFormRadioGroup,
    BFormRadio,
    BIcon,
    BImg,
    BIconArrowDown,
    EditableField,
    MetaScore,
    Platform,
    Pill,
    PricePill,
    ImageGallery,
    FlagIcon,
    UnlinkIcon,
    AuthWrapper,
    IconSet
  },
  DB_FIELD_TO_ITEM_MAP: {
    rating_id: ["game_specific", "rating_id"],
    players: ["game_specific", "num_players"],
    supports_co_op: ["game_specific", "co_op"],
    vgpc_url: ["links", "vgpc"],
    metacritic_url: ["links", "metacritic"]
  },
  AFFILIATE_DISCLOSURE: "GAMEYE may earn a commission, at no extra cost to you, if you use these links and make a purchase.",
  mixins: [utils, imageHelperMixin],
  links: [
    {
      name: "tgdb",
      label: "TGDB"
    },
    {
      name: "vgpc",
      label: "VGPC",
      editable: true
    },
    {
      name: "youtube",
      label: "YouTube"
    },
    {
      name: "ebay",
      label: "eBay"
    },
    {
      name: "amazon",
      label: "Amazon"
    },
    {
      name: "metacritic",
      label: "metacritic",
      editable: true
    },
    {
      name: "hltb",
      label: "howlongtobeat"
    }
  ],
  props: {
    id: {
      type: [String, Number],
      required: true
    }
  },
  computed: {
    allLinks () {
      let dynamicLinks = _.map(this.otherLinks, l => {
        if (_.get(this.game, l.linkVar.split("."))) Vue.set(l, "link", _.get(this.game, l.linkVar.split(".")));
        return l;
      });
      let unionVal = _.union(this.$options.links, dynamicLinks);
      console.table(unionVal)
      return unionVal
    },
    images () {
      if(this.game.images && !_.isEmpty(this.game.images)) {
        return _.flatten(_.values(this.game.images));
      } else {
        return null;
      }
    },
    formattedAltTitles () {
      return this.game.alt_titles ? this.game.alt_titles.join(" • ") : 'No alternate titles'
    },
    gameSpecific () {
      return this.game ? this.game.game_specific || {} : {};
    },
    metascore () {
      if(this.game && this.game.mc) return this.game.mc.score;
      else return null;
    },
    prices () {
      return this.game ? this.game.price || {} : undefined;
    },
    rating () {
      return this.game ? this.parseListForMatchingId(this.metadata.parental_ratings, this.gameSpecific.rating_id) : {};
    },
    visibleTabs () {
      return _.filter(this.tabs, tab => {
        return !tab.authLevel || this.checkUserLevel(tab.authLevel)
      });
    },
    headerBgImage () {
      if (this.images) {
        return (this.getImagesByTypeFromRecord(this.game.images, "Screenshot") || this.getImagesByTypeFromRecord(this.game.images, "Front Box Art") || {})[0];
      } else {
        return undefined;
      }
    },
    headerBgClass () {
      if (this.images && !this.getImagesByTypeFromRecord(this.game.images, "Screenshot")) return "blurred";
      else return "";
    },
    isPendingAndCreator () {
      return ITEM_STATES.NEEDS_REVIEW === this.game.item_state && this.game.creator_user_id === this.loggedInUser?.id;
    }
  },
  data () {
    return {
      editMode: false,
      otherLinks: [
        // Example how to use this. allows editing links not in links array
        /* {
          name: "mc",
          label: "MetaCritic",
          linkVar: "mc.link",
          editable: true
        } */
      ],
      game: {},
      loading: true,
      tabs: [{ value: 1, text: 'Overview'}, { value: 5, text: 'History', icon: 'clock-history', authLevel: this.$options.AUTH_LEVELS.MOD}],
      selectedTab: 1,
      linksMap: {},
      summaryExpanded: false,
      showSummaryExpand: false,
      editHistory: {}
    }
  },
  methods: {
    round(val) {
      return val ? Math.round(val) : val
    },
    getItem() {
      this.loading = true;
      return this.$store.dispatch('encyclopedia/getItemDetails', this.id)
      .then(response => {
        this.game = response;
        this.updateLinks();
        this.loading = false;
        this.$meta().refresh();
      })
    },
    deleteItem () {
      this.$store.dispatch('updates/deleteItem', this.game.item_id)
      .then(() => {
        this.getItem();
      })
    },
    getEditHistory() {
      if (this.editHistory.length > 0)
        return;

      return this.$store.dispatch('encyclopedia/getItemHistory', this.id)
      .then(response => {
        this.editHistory = response;
      })
    },
    selectedTabChanged() {
      if (this.selectedTab == 5)
        this.getEditHistory()
    },
    getLinkObj (name) {
      return _.find(this.game.links, {Type: name});
    },
    getLink (name) {
      let link = this.getLinkObj(name);
      if (link && link.Link !== '') return link.Link;
    },
    getLinkGuess (name) {
      let link = this.getLinkObj(name);
      if (link && link.LinkGuess !== '') return link.LinkGuess;
    },
    copyLinkGuess (name) {
      let guess = this.getLinkGuess(name);
      // have to get index 0 because within v-for loop. breaks in Vue 3
      this.$refs[name + "-efp"][0].$refs.popover.updateFieldValue(guess);
    },
    updateLinks () {
      _.each(this.$options.links, l => {
        Vue.set(this.linksMap, l.name, this.getLink(l.name));
      });
    },
    editFieldFunc (newVal, fieldName) {
      return this.$store.dispatch('updates/editItem', { item_id: this.game.item_id, item_edit: { [fieldName]: newVal } })
        .then(newGame => { 
          this.game = newGame; 
          this.editHistory = {};
        });
    },
    editItemStateFunc (newVal) {
      return this.$store.dispatch('updates/editState', { item_id: this.game.item_id, item_state: newVal })
      .then(() => {
        this.game.item_state = newVal;
      })
    },
    uploadNewImage ({image, type}) {
      let form = new FormData();
      form.append("item_id", this.game.item_id);
      form.append("type_id", type);
      form.append("image", image)

      return this.$store.dispatch("updates/addImage", form)
        .then(this.getItem);
    },
    editImageMetadata ({image, type, attribution}) {
      return this.$store.dispatch("updates/editImage", { item_id: this.game.item_id, image_id: image.ID, to_image_type: type, attribution: attribution })
        .then(this.getItem);
    },
    replaceImage (data) {
      return this.uploadNewImage(data);
    },
    removeImage (imageId) {
      return this.$store.dispatch("updates/deleteImage", { image_id: imageId })
        .then(this.getItem);
    },
    parseColumn (columnName, columnValue) {
      var value
      switch(columnName) {
        case "genre_id": 
          return _.map(this.parseListForMatchingIds(this.values(this.genres), this.parseIntsFromEditList(columnValue)), "name");
        case "developer_id": 
          return _.map(this.parseListForMatchingIds(this.values(this.developers), this.parseIntsFromEditList(columnValue)), "name");
        case "publisher_id": 
          return _.map(this.parseListForMatchingIds(this.values(this.publishers), this.parseIntsFromEditList(columnValue)), "name");
        case "rating_id": 
          value = this.parseListForMatchingId(this.metadata.parental_ratings, parseInt(columnValue))
          break;
        case "country_id": 
          value = this.parseListForMatchingId(this.metadata.countries, parseInt(columnValue));
          break;
        case "category_id": 
          value = this.parseListForMatchingVal(this.assignableCategories, {value: parseInt(columnValue)})
          break;
        case "item_state_id": 
          value = this.parseListForMatchingId(this.metadata.item_states, parseInt(columnValue));
          break;
        case "release_type_id": 
          value = this.parseListForMatchingId(this.metadata.release_types, parseInt(columnValue));
          break;
        case "platform_id": 
          value = this.parseListForMatchingId(this.platformsList, parseInt(columnValue));
          break;
      }

      // If value from map was null, just restore it to columnName
      if (!value)
        return columnValue;
        
      return value.name;
    }
  },
  created () {
    this.getItem();
  },
  updated () {
    this.showSummaryExpand = this.$refs.summary && this.$refs.summary.scrollHeight > this.$refs.summary.clientHeight;
  },
  watch: {
    id: function () {
      this.getItem();
      this.getEditHistory();
    }
  }
};
</script>

<style scoped lang="scss">
@import "@/assets/styles/_variables.scss";

.header {
  @extend .body;
  // max-height: 350px;
  padding: 64px 0 32px;
  position: relative;
  ::v-deep .alt_titles-edit .bi-pencil {
    fill: white;
  }
  .header-bg, .header-bg > .overlay, .header-bg > .l-overlay {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    right: 0;
  }
  .header-bg {
    > .bg-img {
      width: 50%;
      text-align: right;
      overflow-y: hidden;
      &::v-deep img {
        min-width: 50%;
        min-height: 100%;
        max-height: unset;
        position: absolute;
        right: 0;
        bottom: 0;
        filter: blur(2px);
      }
    }
    &.blurred {
      filter: blur(20px);
      > .bg-img::v-deep img {
        top: 50%;
        transform: translateY(-50%);
      }
    }
    > .overlay {
      background: url('@/assets/bg-gradient-1px.png');
      background-repeat: repeat-y;
      width: calc(50% + 5px);
    }
    > .l-overlay {
      right: unset;
      left: 0;
      width: 50%;
      background-color: var(--background-color);
    }
  }
  .header-content > div:not(.img-container) {
    row-gap: 24px;
    padding: 15px 0;
    width: 100%;
    row-gap: 16px;
  }
  .content {
    padding: 16px;
    flex-grow: 2;
  }
  .img-container {
    height: 250px;
    width: 250px;
    ::v-deep img {
      box-shadow: 3px 3px 8px rgba(0,0,0,0.35);
    }
  }
  .title {
    text-shadow: 1px 1px 2px rgba(0,0,0,0.35);
    font-weight: 500;
    font-size: 1.4rem;
  }
}

.tabs {
  padding: 0 15px;
  background-color: #3E4047;
  z-index: 10;
  ::v-deep label {
    border-radius: 0;
    color: white;
    font-size: 1rem;
    border: none;
    &.active {
      background-color: #202133;
      border-top: 2px solid white;
    }
    &:focus, &.focus {
      box-shadow: none;
    }
  }
}

.left-col {
  width: 300px;
  min-width: 300px;
  margin-right: 32px;
}

.right-col {
  flex-grow: 1;
}

.title {
  font-size: 1.2rem;
  line-height: 1.2rem;
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}

.kpi {
  max-width: fit-content;
  padding: 8px 24px;
  background-color: #3E4047CC;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  align-items: center;
  .label {
    font-size: 1.2em;
    font-weight: 500;
    margin-bottom: 4px;
  }
}

a.kpi {
  color: currentColor;
  text-decoration: none;
  &:hover {
    color: currentColor;
    background-color: #3E404799;
  }
}

.game-details {
  width: calc(100% + 32px);
  display: flex;
  flex-direction: column;
  margin: -16px; //might want to instead remove padding on parent.. effects all pages, though
  .body, .tabs-content, .header-content {
    margin: 0 auto;
    min-width: $bodyWidth;
    width: $bodyWidth;
  }
  > .tabs, > .content {
    padding: 0 60px;
  }
}

.game-details .details {
  display: flex;
  justify-content: space-between;
}

.main {
  padding-top: 30px;
}

.prices {
  padding: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  column-gap: 8px;
  flex-wrap: wrap;
  .label-value > .pill {
    width: 120px;
    min-width: 120px;
    max-width: 120px;
    padding: 8px 0;
    text-align: center;
    position: relative;
    ::v-deep {
      > div:first-child {
        position: absolute;
      }
      > span.value {
        flex-grow: 1;
        margin: 0 20px;
      }
    }
  }
}

.affiliates {
  gap: 8px;
  font-size: 0.7rem;
  .affiliate {
    padding: 0 8px;
    &:not(:last-child) {
      border-right: 1px solid var(--gray);
      padding-right: 16px;
    }
  }
}

.details .top {
  padding-top: 10px;
}

.main-attributes {
  &.section {
    margin-bottom: 40px;
  }
  width: 100%;
  .label-value {
    display: grid;
    grid-template-columns: 5fr 8fr;
    .label {
      font-size: 0.9em;
    }
    .value {
      display: flex;
      flex-wrap: wrap;
      .pill {
        margin-right: 5px;
      }
    }
  }
}

.details {
  font-size: 0.9rem;
}

.prices .label-value {
  max-width: fit-content;
  padding: 0 24px 0 0;
  justify-content: space-between;
  margin: 8px;
  flex-direction: column;
  gap: 8px;
  border: none;
  > .label {color: white;}
  &:last-child {
    border-right: none;
    padding-right: 0;
  }
  .icon-set {
    width: 35px;
    text-align: center;
  }
}

.label-value {
  display: flex;
  width: 100%;
  padding: 16px 4px 12px;
  align-items: center;
  border-top: 1px solid #505050;
  &:last-child {
    border-bottom: 1px solid #505050;
  }
  > div .pill {
    padding-bottom: 4px;
  }
  > .label {
    color: #ABA8A8;
    padding-bottom: 4px;
    flex-grow: 1;
  }
  svg {
    fill: white;
  }
}

.section {
  margin-bottom: 20px;
}

.section-header {
  font-size: 1.1rem;
  margin-bottom: 12px;
  margin-left: 4px;
}

.description {
  p {
    color: var(--lightgrey-font-color);
    white-space: pre-wrap;
    margin-bottom: 0;
  }
  &:not(.expanded) p {
    overflow: hidden;
    line-clamp: 4;
    -webkit-line-clamp: 4;
    display: -webkit-box;
    box-orient: vertical;
    -webkit-box-orient: vertical;
  }
}

.expand-link > button {
  padding: 0;
  font-size: 0.8rem;
}

.card {
  width: 100%;
  min-width: 100%;
  background-color: transparent;
  border: none;
  padding: 0;
  .title {
    font-size: 1.3em;
    margin-bottom: 0.5em;
  }
  .content {
    border-radius: 12px;
    padding: 1.5em;
    background-color: var(--card-bg);
    box-shadow: 3px 0 3px rgba(0,0,0,0.35);
  }
}

#edit-history {
  width: 100%;
  td, th {
    padding: 0.5em;
    border: 1px solid #696969;
  }
}

.sticky-below-nav {
  right: max(32px, calc(((100% - $bodyWidth) / 2) + 32px))
}

.sticky-below-nav > .btn-outline-secondary {
  background-color: transparent;
}

.pill::v-deep > .badge {
  max-width: 120px;
  span {
    overflow: hidden;
    text-overflow: ellipsis;
  }
}

@media (max-width: calc($bodyWidth + 90px)) {
  .game-details {
    .body, .tabs-content, .header-content {
      min-width: unset;
      width: unset;
      margin: 0;
    }
  }
}

@media (max-width: calc($mediumWidth + 60px)) {
  .body > div.flex{
    flex-direction: column;
    .left-col {
      width: 100%;
      .main-attributes {
        margin-bottom: 24px;
      }
      .card .content {
        columns: 2;
        .label-value:last-child {
          border-bottom: none;
        }
      }
    }
    .g-container {
      padding-top: 16px;
    }
  }
  .game-details {
    > .tabs, > .content {
      padding: 0 30px;
    }
  }
  .header .header-content > div:not(.img-container) {
    row-gap: 8px;
    padding-left: 16px;
    padding-right: 16px;
  }
}

@media (min-width: calc($mediumWidth + 60px)) {
  .section.card > .content {
    background: transparent;
    box-shadow: none;
    padding: 0;
    border: none;
  }
}

@media (max-width: calc($smallWidth + 30px)) {
  .game-details {
    > .tabs, > .content {
      padding: 0 15px;
    }
  }
}

@media (max-width: $mobileWidth) {
  .flex.mobile-switch {
    flex-direction: column;
    row-gap: 8px;
  }
  .body .left-col .card.main-attributes .content {
    columns: 1;
  }
  .header {
    .header-bg {
      ::v-deep img {
        filter: blur(20px);
        height: 100%;
        max-width: unset;
      }
      > .l-overlay {
        display: none;
      }
      > .overlay {
        background-color: rgba(0,0,0,0.35);
        width: 100%;
        height: 100%;
      }
    }
    .img-container {
      margin: auto;
    }
  }
  .card .prices {
    flex-direction: column;
    .label-value {
      flex-direction: row;
      border-right: none;
      justify-content: space-between;
      max-width: unset;
      padding: 8px;
      margin: 0;
      border-color: var(--gray);
      &:last-child {
        padding: 8px;
        border-bottom: none;
      }
      &:first-child {
        border-top: none;
      }
    }
  }
}
</style>
