import Vue from "vue";
import VueRouter from "vue-router";
import Meta from "vue-meta";
import PageBase from "@/views/PageBase";
import Home from "@/views/Home";
import Encyclopedia from "@/views/Encyclopedia";
import Collection from "@/views/Collection";
import Tools from "@/views/Tools";
import Browse from "@/views/Browse";
import ItemDetails from "@/views/ItemDetails";
import Login from "@/views/Login";
import DataTools from "@/views/tools/DataTools";
import Regionification from "@/views/tools/Regionification";
import Pricification from "@/views/tools/Pricification";
import VGPCfication from "@/views/tools/VGPCfication";
import TGDBQueue from "@/views/tools/TGDBQueue";
import DataQueue from "@/views/tools/DataQueue";
import DeletedItemsReview from "@/views/tools/DeletedItemsReview";
import CompaniesManager from "@/views/tools/CompaniesManager";
import UserManager from "@/views/tools/UserManager";
import DataManager from "@/views/tools/DataManager";
import FirebaseAuthHandler from "@/views/FirebaseAuthHandler";
import { getCurrentUser } from "@/mixins/utils.js";
import { AUTH_LEVELS } from "@/store/constants";
import store from "@/store/index";
import _ from "underscore";

Vue.use(VueRouter);
Vue.use(Meta);

const routes = [
  {
    path: "",
    component: PageBase,
    inNav: false,
    children: [
      {
        path: "",
        name: "Home",
        component: Home,
        inNav: false
      },
      {
        path: "/encyclopedia",
        component: Encyclopedia,
        icon: "nav_encyclopedia",
        label: "Encyclopedia",
        unlocked: true,
        inNav: true,
        children: [
          {
            path: ":id(\\d+)",
            name: "Details",
            component: ItemDetails,
            inNav: false
          },
          {
            path: ":category?",
            name: "Browse",
            component: Browse,
            props: route => ({category: route.params.category, page: parseInt(route.query.p), 
              pageSize: parseInt(route.query.ps), query: route.query.q, sort: route.query.s, 
              displayType: route.query.dt, filters: route.query.f, store: 'encyclopedia'}),
            inNav: false
          }
        ]
      },
      // {
      //   path: "/my_collection",
      //   component: Browse,
      //   icon: "nav_library",
      //   label: "Library",
      //   unlocked: true,
      //   inNav: true,
      //   meta: {
      //     requiresAuth: true
      //   },
      //   children: [
      //     {
      //       path: ":category?",
      //       name: "Library",
      //       component: Browse,
      //       props: route => ({isUserCollection: true, category: route.params.category, 
      //         page: parseInt(route.query.p), pageSize: parseInt(route.query.ps), query: route.query.q, sort: route.query.s, 
      //         displayType: route.query.dt, filters: route.query.f, store: 'userCollection'}),
      //       inNav: true
      //     }
      //   ]
      // },
      {
        path: "/my_collection",
        component: Collection,
        icon: "nav_library",
        label: "Library",
        unlocked: true,
        inNav: true,
        meta: {
          requiresAuth: true
        },
        children: [
          {
            path: ":category?",
            name: "Library",
            component: Browse,
            props: route => ({isUserCollection: true, category: route.params.category, 
              page: parseInt(route.query.p), pageSize: parseInt(route.query.ps), query: route.query.q, sort: route.query.s, 
              displayType: route.query.dt, filters: route.query.f, store: 'userCollection'}),
            inNav: true
          }
        ]
      },
      {
        path: "/wishlist",
        name: "WishList",
        component: Home,
        icon: "nav_wishlist",
        unlocked: false,
        inNav: true,
        meta: {
          requiresAuth: true
        }
      },
      {
        path: "/sell",
        name: "Sell",
        component: Home,
        icon: "nav_sell",
        label: "Sell/Trade",
        width: "22px",
        unlocked: false,
        inNav: true,
        meta: {
          requiresAuth: true
        }
      },
      {
        path: "/userauth",
        name: "User Auth",
        component: FirebaseAuthHandler,
        meta: {
          hideLinks: true,
          hideSearch: true
        },
        props: route => ({ mode: route.query.mode, fbCode: route.query.oobCode, continueUrl: route.query.continueUrl, apiKey: route.query.apiKey })
      },
      {
        path: "/tools",
        name: "Tools",
        component: Tools,
        icon: "nav_tools",
        unlocked: true,
        inNav: true,
        childMenu: true,
        meta: {
          requiresAuth: true,
          requiredAuthLevel: AUTH_LEVELS.MOD, //hard-coded to Mod for now
          toolsHeader: true,
          hideSearch: true,
          hideLinks: true,
          noFooter: true
        },
        children: [
          {
            path: "datatools",
            label: "Data Tools",
            defaultName: "Data Tools", //maps to redirect/default path. if left as name, gets warning in console
            meta: {title: "Data Tools", requiredAuthLevel: AUTH_LEVELS.MOD},
            component: DataTools,
            inNav: true,
            children: [
              {
                path: "",
                name: "Data Tools",
                redirect: { name: "Regionification" }
              },
              {
                path: "regionification",
                name: "Regionification",
                meta: {title: "Data Tools", requiredAuthLevel: AUTH_LEVELS.MOD},
                component: Regionification,
                props: () => ({ store: "encyclopedia" })
              },
              {
                path: "pricification",
                name: "Pricification",
                meta: {title: "Data Tools", requiredAuthLevel: AUTH_LEVELS.MOD},
                component: Pricification,
                props: () => ({ store: "encyclopedia" })
              },
              {
                path: "vgpcfication",
                name: "VGPCfication",
                meta: {requiredAuthLevel: AUTH_LEVELS.MOD},
                component: VGPCfication
              },
              {
                path: "tgdbqueue",
                name: "TGDBQueue",
                meta: {requiredAuthLevel: AUTH_LEVELS.MOD},
                component: TGDBQueue
              },
              {
                path: "dataqueue",
                name: "DataQueue",
                meta: {requiredAuthLevel: AUTH_LEVELS.MOD},
                component: DataQueue
              },
              {
                path: "deletedItems",
                name: "DeletedItems",
                meta: {requiredAuthLevel: AUTH_LEVELS.MOD},
                component: DeletedItemsReview,
                props: () => ({store: 'encyclopedia' }),
              },
              {
                path: "companyManager",
                name: "CompaniesManager",
                meta: {requiredAuthLevel: AUTH_LEVELS.MOD},
                component: CompaniesManager,
                props: () => ({store: 'encyclopedia' }),
              }
            ]
          },
          {
            path: "usermanager",
            name: "User Manager",
            label: "User Management",
            meta: {title: "User Manager", requiredAuthLevel: AUTH_LEVELS.ADMIN},
            component: UserManager,
            inNav: true
          },
          {
            path: "datamanager",
            name: "Data Manager",
            label: "Server Data Management",
            meta: {title: "Server Data Manager", requiredAuthLevel: AUTH_LEVELS.ADMIN},
            component: DataManager,
            inNav: true
          }
        ]
      }
    ]
  },
  {
    path: "/login",
    name: "Login",
    component: Login,
    beforeEnter: async (to, from, next) => {
      if (await getCurrentUser()) next(false);
      else next();
    }
  },
  {
    path: "/signup",
    name: "SignUp",
    component: Login,
    beforeEnter: async (to, from, next) => {
      if (await getCurrentUser()) next(false);
      else next();
    },
    props: { signup: true }
  }
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
  scrollBehavior: (to, from, savedPosition) => {
    if (savedPosition) {
      return savedPosition;
    } else if (to.hash) {
      return {
        selector: to.hash
      };
    } else {
      return { x: 0, y: 0 };
    }
  }
});

router.beforeEach(async (to, from, next) => {
  const requiresAuth = to.matched.some(record => record.meta.requiresAuth);
  const requiredAuthLevels = _.compact(_.pluck(_.pluck(to.matched, 'meta'), 'requiredAuthLevel'));
  getCurrentUser().then(user => {
    if (requiresAuth && !user) {
      const loginpath = to.fullPath;
      next({ name: 'Login', query: { from: loginpath } });
    } else if (!requiresAuth && !user) {
      next();
    } else {
      // If we already have the user data, we do not need to reload it on every call
      if (!store.state.loadingUser) {
        nextIfAuthorized(requiredAuthLevels, next);
      } else {
        // there is a user logged in, need metadata for auth levels
        //***** should we separate out auth level stuff from metadata?
        Promise.all([store.dispatch('getUserInfo'), store.dispatch('meta/getMetadata')]).then(() => {
          nextIfAuthorized(requiredAuthLevels, next);
        });
      }
    }
  })
})

function nextIfAuthorized(requiredAuthLevels, next) {
  var userLevel = store.getters.getUserLevel;
  if (_.isEmpty(requiredAuthLevels) || userLevel >= _.max(requiredAuthLevels)) {
    next();
  } else {
    // user shouldn't have access to this URL
    //PLACEHOLDER
    alert("Looks like you've trekked somewhere you can't be. Please go somewhere else");
  }
}

export default router;
